<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="3">
        <v-card>
          <v-simple-table dense outline>
            <thead>
              <tr>
                <th class="text-center">GRADE</th>
                <th class="text-center">NUMBER OF STUDENTS</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, i) in chartData" :key="i">
                <td class="text-center">{{ `${data[0]}` }}</td>
                <td class="text-center">{{ `${data[1]}` }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col cols="12" lg="9">
        <v-card class="mt-6 mx-auto">
          <v-sheet
            dark
            class="v-sheet--offset mx-auto"
            color="white"
            elevation="12"
            max-width="calc(100% - 32px)"
          >
            <div class="pa-5">
              <column-chart
                :data="chartData"
                xtitle="Grades"
                ytitle="Number of Students"
                :download="true"
                :colors="[colors]"
              ></column-chart>
            </div>
          </v-sheet>

          <v-card-text class="pt-0">
            <div class="text-h6 font-weight-medium mb-1">
              {{ courseDetails.course.coursecode_coursename }}
            </div>
            <div class="subheading font-weight-medium">
              {{ `Taught by ${courseDetails.user.title_name}` }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          fixed-header
          disable-pagination
          :headers="headers"
          :items="items"
          hide-default-footer
          class="elevation-2"
          item-key="id"
        >
          <template v-slot:header.mid_sem_score="{ header }">
            {{ `${header.text} (${getters_requested_grade.class}%)` }}
          </template>
          <template v-slot:header.end_of_sem_score="{ header }">
            {{ `${header.text} (${getters_requested_grade.exams}%)` }}
          </template>
          <template v-slot:header.total_score="{ header }">
            {{
              `${header.text} (${
                parseInt(getters_requested_grade.exams) +
                parseInt(getters_requested_grade.class)
              }%)`
            }}
          </template>
          <template #item.student_info="{ item }">
            <v-list-item>
              <v-list-item-avatar>
                <v-img
                  alt="require('@/assets/download.jpg')"
                  :lazy-src="item.student.avatar_url"
                  :src="item.student.avatar_url"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                {{ item.student.index_number_title_name }}
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-card v-if="items.length" v-intersect="intersected">
      <v-progress-linear
        :indeterminate="true"
        v-if="loadingScroll"
      ></v-progress-linear>
    </v-card>

    <v-overlay absolute :value="overlay" :opacity="0.5">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
      <div class="mt-2 text-center">Loading</div>
    </v-overlay>
  </v-container>
</template>
<script>
  import {
    defineComponent,
    reactive,
    toRefs,
    inject,
    getCurrentInstance,
  } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  export default defineComponent({
    setup() {
      const vm = getCurrentInstance();
      const { getScriptUpload, getRequestedGrade, signOut } = useActions([
        "getScriptUpload",
        "getRequestedGrade",
        "signOut",
      ]);

      const {
        getters_upload_script,
        getters_script_upload_meta,
        getters_requested_grade,
      } = useGetters([
        "getters_upload_script",
        "getters_script_upload_meta",
        "getters_requested_grade",
      ]);

      const scriptUpload = reactive({
        headers: [
          { text: "NAME OF STUDENT", value: "student_info" },
          {
            text: "MID SEMESTER SCORE",
            value: "mid_sem_score",
            align: "center",
            sortable: false,
          },
          {
            text: "END OF SEMESTER SCORE",
            value: "end_of_sem_score",
            align: "center",
            sortable: false,
          },
          {
            text: "TOTAL SCORE",
            value: "total_score",
            align: "center",
            sortable: false,
          },
          { text: "GRADE", value: "grade", align: "center", sortable: false },
        ],
        isLoaded: true,
        basketWithIndex: [],
        isIntersecting: false,
        page: 1,
        loadingScroll: false,
        items: [],
        overlay: true,
        chartData: {},
        colors: [],
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        actionResponse: false,
        color: "",
      });

      const {
        colors,
        isLoaded,
        loadingScroll,
        page,
        isIntersecting,
        items,
        overlay,
        chartData,
        actionResponse,
        msgHeader,
        msgBody,
        msgIcon,
        color,
      } = toRefs(scriptUpload);

      const scriptId = inject("scriptId");
      const courseDetails = inject("courseDetails");

      Promise.all([
        getRequestedGrade(courseDetails.value?.header?.grade_id),
        getScriptUpload({ page: page.value, id: scriptId.value }),
      ]).then(() => {
        isLoaded.value = false;
        items.value = [...getters_upload_script.value];
        overlay.value = false;

        let newGrade = getters_requested_grade.value.grades.reduce(
          (acc, curr) => {
            acc[curr.grade] = 0;
            return acc;
          },
          {}
        );

        chartData.value = [...items.value].reduce((acc, curr) => {
          acc[curr.grade] = (acc[curr.grade] || 0) + 1;
          return acc;
        }, {});

        chartData.value = Object.entries({ ...newGrade, ...chartData.value });
        //chartData.value = { ...newGrade, ...chartData.value };
        for (const key in chartData.value) {
          if (chartData.value[key][0] === "A") {
            colors.value.push("#1B5E20BF");
          }

          if (chartData.value[key][0] === "A-") {
            colors.value.push("#388E3CBF");
          }

          if (chartData.value[key][0] === "B+") {
            colors.value.push("#66BB6ABF");
          }

          if (chartData.value[key][0] === "B") {
            colors.value.push("#A5D6A7BF");
          }

          if (chartData.value[key][0] === "B-") {
            colors.value.push("#DCEDC8BF");
          }

          if (chartData.value[key][0] === "C+") {
            colors.value.push("#FFE0B2BF");
          }

          if (chartData.value[key][0] === "C") {
            colors.value.push("#FFB74DBF");
          }

          if (chartData.value[key][0] === "C-") {
            colors.value.push("#F57C00BF");
          }

          if (chartData.value[key][0] === "D") {
            colors.value.push("#E65100BF");
          }

          if (chartData.value[key][0] === "F") {
            colors.value.push("#D50000BF");
          }

          if (["IC", "Incomplete"].includes(chartData.value[key][0])) {
            colors.value.push("#01579BBF");
          }
        }
      });

      const intersected = (entries) => {
        isIntersecting.value = entries[0].isIntersecting;

        if (!isIntersecting.value) {
          return;
        }
        loadingScroll.value = true;

        if (
          getters_script_upload_meta.value.current_page >=
          getters_script_upload_meta.value.last_page
        ) {
          loadingScroll.value = false;
          return;
        }

        page.value++;
        getScriptUpload({ page: page.value, id: scriptId.value })
          .then(() => {
            loadingScroll.value = false;
            items.value = [
              ...new Set([...items.value, ...getters_upload_script.value]),
            ];
          })
          .catch(() => {
            loadingScroll.value = false;
          })
          .catch((e) => {
            actionResponse.value = true;
            msgHeader.value = "Error";
            msgIcon.value = "mdi-close-circle";
            color.value = "error";
            switch (e.response.status) {
              case 423:
                msgBody.value = e.response.data.message;
                signOut().then(() => {
                  vm.proxy.$router.replace({
                    name: "Login",
                  });
                });
                break;
              case 403:
                msgBody.value = e.response.data.message;
                break;

              default:
                msgBody.value =
                  "Try Again Later Or Call The System Administrator";

                break;
            }
          });
      };

      return {
        ...toRefs(scriptUpload),
        intersected,
        getters_requested_grade,
        courseDetails,
      };
    },
  });
</script>
<style>
  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.03);
  }
  .v-sheet--offset {
    top: -24px;
    position: relative;
  }
</style>