<template>
  <v-container fluid>
    <PageHeader>
      <span slot="courses" class="font-weight-light">MY COURSES </span>
      <b>HISTORY</b>
    </PageHeader>

    <v-row grow>
      <v-col>
        <v-data-table
          fixed-header
          disable-pagination
          :headers="headers"
          :items="items"
          hide-default-footer
          class="elevation-2"
          item-key="id"
          :loading="isLoaded"
        >
          <template #item.program="{ value }">
            {{ value.program_name_certificate }}
          </template>
          <template #item.view_details="{ item }">
            <v-btn
              :disabled="!getters_abilities.includes('my_courses_access')"
              @click="viewDetails(item)"
              dark
              right
              large
              icon
            >
              <v-icon dark class="primary--text"> mdi-open-in-new </v-icon>
            </v-btn>
          </template>
          <template v-slot:no-data>
            <span class="subheading font-weight-bold">
              No Permission(s) available
            </span>
          </template>
        </v-data-table>

        <v-card
          v-if="getters_course_uploads_paginate.length"
          v-intersect="intersected"
        >
          <v-progress-linear
            :indeterminate="true"
            v-if="loadingScroll"
          ></v-progress-linear>
        </v-card>
      </v-col>
    </v-row>

    <Response style="z-index: 999" v-if="actionResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>

    <v-navigation-drawer
      fixed
      v-model="script"
      :permanent="script"
      right
      width="75%"
      class="shadow"
      clipped
      elevation-19
    >
      <div class="mt-15 d-flex justify-space-between">
        <v-spacer></v-spacer>
        <v-btn class="ma-2" @click="script = false" icon>
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </div>
      <CourseHistory v-if="script" />
    </v-navigation-drawer>
  </v-container>
</template>

<script>
  import { getCurrentInstance, provide, reactive, toRefs } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import Response from "@/components/ActionResponse/Response";
  import PageHeader from "@/components/slots/PageHeader";
  import CourseHistory from "@/components/Faculty/CourseHistory.vue";

  export default {
    components: { Response, PageHeader, CourseHistory },
    setup() {
      const vm = getCurrentInstance();
      const { signOut, courseUpload } = useActions(["signOut", "courseUpload"]);

      const {
        getters_course_uploads_meta,
        getters_course_uploads_paginate,
        user,
        getters_abilities,
      } = useGetters([
        "getters_course_uploads_paginate",
        "getters_course_uploads_meta",
        "user",
        "getters_abilities",
      ]);

      const myCourseUploads = reactive({
        script: false,
        isLoaded: true,
        headers: [
          {
            text: "CALENDAR",
            value: "header.calendar.academic_calendar",
            sortable: false,
            width: 120,
          },
          { text: "PROGRAM", value: "header.program.program_name_certificate" },
          {
            text: "COURSE",
            value: "course.coursecode_coursename",
          },
          {
            text: "CREDIT HOURS",
            value: "course.credit_hours",
            align: "center",
            sortable: false,
            width: 85,
          },
          { text: "LEVEL", value: "header.level.level", width: 85 },
          { text: "SEMESTER", value: "header.semester.semester", width: 145 },
          { text: "SESSION", value: "session.session", width: 100 },
          { text: "CAMPUS", value: "header.campus.campus_name", width: 100 },
          {
            text: "VIEW DETAILS",
            value: "view_details",
            sortable: false,
            align: "center",
            width: 80,
          },
        ],
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        actionResponse: false,
        isIntersecting: false,
        page: 1,
        loadingScroll: false,
        items: [],
        color: null,
        scriptId: 0,
        gradeId: 0,
        courseDetails: {},
      });

      const {
        script,
        msgHeader,
        msgBody,
        msgIcon,
        actionResponse,
        isIntersecting,
        page,
        loadingScroll,
        items,
        color,
        scriptId,
        gradeId,
        courseDetails,
      } = toRefs(myCourseUploads);

      courseUpload({ page: page.value, id: user.value.id })
        .then(() => {
          items.value = [...getters_course_uploads_paginate.value];
          myCourseUploads.isLoaded = false;
        })
        .catch((e) => {
          actionResponse.value = true;
          msgHeader.value = "Error";
          msgIcon.value = "mdi-close-circle";
          color.value = "error";
          switch (e.response.status) {
            case 423:
              msgBody.value = e.response.data.message;
              signOut().then(() => {
                vm.proxy.$router.replace({
                  name: "Login",
                });
              });
              break;
            case 403:
              msgBody.value = e.response.data.message;
              break;

            default:
              msgBody.value = "Try Again Later Or Call The System Administrator";

              break;
          }
        });

      const intersected = (entries) => {
        isIntersecting.value = entries[0].isIntersecting;

        if (!isIntersecting.value) {
          return;
        }
        loadingScroll.value = true;

        if (
          getters_course_uploads_meta.value.current_page >=
          getters_course_uploads_meta.value.last_page
        ) {
          loadingScroll.value = false;
          return;
        }

        page.value++;
        courseUpload({ page: page.value, id: user.value.id }).then(() => {
          loadingScroll.value = false;
          items.value = [
            ...new Set([
              ...items.value,
              ...getters_course_uploads_paginate.value,
            ]),
          ];
        });
      };

      const viewDetails = (item) => {
        scriptId.value = item.id;
        gradeId.value = item?.header?.grade_id;
        script.value = true;
        courseDetails.value = item;
      };

      provide("color", color);
      provide("scriptId", scriptId);
      provide("gradeId", gradeId);
      provide("courseDetails", courseDetails);

      return {
        ...toRefs(myCourseUploads),
        getters_course_uploads_paginate,
        intersected,
        items,
        viewDetails,
        getters_abilities,
      };
    },
  };
</script>
